.list-table-body {
    padding: 30px !important;
    padding-top: 15px;
    width: 100%;
    min-height: calc(100vh - 170px);
    border-radius: 10px;
    background-color: #FFF;
    position: relative;
    top: 20px;
    left: 0px;
    z-index: 1;

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__filter {
        // border-bottom: 1px solid #F4F5F6;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    &__deactivate {
        // border-bottom: 1px solid #F4F5F6;
        margin-bottom: 15px;
        padding-bottom: 15px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__error {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }

    @media screen and (max-width: 991px) {
        padding: 10px;
        padding-right: 0px;
        display: block;
        min-height: calc(100vh - 80px);
        top: 100px;
    }

    @media (min-width: 1200px) {
        max-width: 1260px !important;
    }
}