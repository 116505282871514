.report-side-bar {
    border: 0px solid coral;
    padding-right: 10px;

    &__nav{
        list-style-type: none;
        
        &__item{
            margin-bottom: 5px;

            &__button{
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                color: #505A62;
                padding: 7px 15px;
                width: 100%;
                text-align: left;
                border: 0px;
                background-color: transparent;
                display: flex;
                border: 1px solid #505A62;
                border-radius: 7px;

                &:hover{
                    background-color: #0095F6;
                    color: #FFFFFF;
                    border-radius: 7px;
                    cursor: pointer;
                }

                &__icon{
                    // filter: hue-rotate(180deg);
                    font-size: 20px;
                    margin-right: 5px;

                    @media screen and (max-width: 991px) {
                        font-size: 16px;     
                        margin-right: 0px; 
                        width: 100%;
                        text-align: center;              
                    }
                }

                &--active {
                    // background-color: #EBEEF0;
                    background-color: #0095F6;
                    color: #FFFFFF;
                    border-radius: 7px;
                }

                @media screen and (max-width: 991px) {
                    flex-direction: column;
                    text-align: center;
                }
            }
        }

        @media screen and (max-width: 991px) {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
        }
    }
}
