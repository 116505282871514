.scrollable{
    height: calc(100vh - 320px);
    overflow-y: auto;

    @media screen and (max-width: 991px){
        min-height: calc(100vh - 110px);
        padding-right: 15px;
    }
}
.infinite-scroll{
    overflow: visible !important;

    &__spinner{
        text-align: center;
    }
}
.list-table {
    border: 0px solid coral;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
    overflow-x: hidden;

    &__header {
        position: sticky;
        top: 0px;
        z-index: 3;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 1px #666;
        
        &__item {
            font-family: "Roboto", sans-serif;
            color: #555;
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 10px;
            padding-top: 5px;
            padding-left: 5px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400;
            user-select: none;

            &__sort {
                margin-left: 7px;      
                display: inline-block;          
                border: 0px solid coral;
                
                &__icon {
                    border: 0px solid coral;
                    margin-top: -3px;;
                }
            }

            &__resizer {
                position: absolute;
                border: 1px solid #EEEEEE;
                background-color: #EEEEEE;
                right: 5px;
                height: 25px;
                width: 1px;
                top: 0;
                touch-action: none;    
            }

            &:last-child > &__resizer {
                display: none;
            }

            &:last-child{
                position: relative;
                z-index: 2;
            }
            
            &:nth-child(1)  {
                background-color: #FFFFFF;
            }
        }

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    &__body {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #1F2747;
        border: 0px solid coral;
        display: block;
        

        &__row {
            border-bottom: 1px solid #FAF5F6;

            &__cell {
                padding: 10.5px 0px;
                padding-left: 5px;
                border: 0px solid #F9FAFA;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &__header {
                    display: none;

                    @media screen and (max-width: 991px) {
                        display: block;
                        font-family: "Roboto", sans-serif;
                        font-weight: 500;
                        text-align: left; 
                    }
                }

                &__data {
                    display: inline;
                    font-family: "Roboto", sans-serif;
                    font-size: 14px;
                }

                // &:first-child >&__header {

                //     @media screen and (max-width: 991px) {
                //         display: none;
                //     }
                // }

                // &:first-child >&__data {
                //     pointer-events: auto;
                    
                // }

                // &:first-child {
                //     pointer-events: none;
                // }

                // &:nth-child(2) {
                //     color: #687178
                // }

                // &:nth-child(1)>&__header {

                //     @media screen and (max-width: 991px) {
                //         display: none;
                //     }
                   
                // }

                &:nth-child(1) {
                    font-weight: 500;
                    color: #1F2747;
                    background-color: #FFFFFF;

                    @media screen and (max-width: 991px) {
                        font-weight: 400;
                        background-color: transparent;
                    }
                }

                // &:last-child {
                //     overflow: visible;
                //     text-align: right;

                //     @media screen and (max-width: 991px) {
                //         border: 0px;
                //     }
                // }

                // &:last-child>&__header {
                //     display: none;
                // }

                @media screen and (max-width: 991px) {
                    display: flex;
                    justify-content: space-between;
                    width: 100% !important;
                    border-bottom: 1px solid #DFDFDF;
                }
            }

            &:hover {
                background-color: #F9FAFA;
                cursor: pointer;        
            }

            &:hover  &__cell:nth-child(1) {
                background-color: #F9FAFA;

                @media screen and (max-width: 991px) {
                    background-color: #F1F1F1;
                }
            }

            &:nth-child(even) {
                // background-color: #F9FAFA;

                @media screen and (max-width: 991px) {
                    background-color: #F1F1F1;
                }
            }

            @media screen and (max-width: 991px) {
                display: flex;
                flex-direction: column;
                width: 100% !important;
                margin-bottom: 15px;
                border-bottom: 0px;
                padding: 5px;
            }
        }

        @media screen and (max-width: 991px) {
            display: block;
            width: 100% !important;
        }
    }

    @media screen and (max-width: 991px) {
        display: block;
        width: 100% !important;
    }
}