.form-body{
    display: flex;
    justify-content: right;
    margin-bottom: 70px;
    border:0px solid coral;
    position: relative;
  
    &__wrapper{
        width: 100%;
        padding: 0px 0px;
        border: 0px solid green;
        background-color: #FFFFFF;
        border-radius: 10px;

        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }

    &__spinner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    @media screen and (max-width: 991px) {
        justify-content: center;
    }

}